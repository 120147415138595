import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import experis from "../assets/experis.png";
import lnu from "../assets/lnu.png";
import wip from "../assets/wip.png";
import website from "../assets/website.png";

export function ProjectsPage() {
  return (
    <Container>
      <Row className="text-center mt-5">
        <h1>My Projects</h1>
      </Row>
      <Row className="d-flex justify-content-center align-items-start">
        <Col lg={4} md={6} xs={10} className="p-0 m-0">
          <Card
            className="m-3 hovering text-decoration-none"
            as={Link}
            to={"/projects/academy"}
          >
            <Card.Img
              variant="top"
              style={{
                objectFit: "cover",
                maxHeight: "10rem",
                width: "100%",
                height: "100%",
              }}
              src={experis}
            />
            <Card.Body>
              <Card.Title>Experis Academy / Noroff bootcamp</Card.Title>
              <Card.Text>
                A fullstack development course with an emphasis on Java,
                Object-Oriented Programming (OOP), REST APIs, security,
                JavaScript, React and Angular. The course included hands-on
                experience through multiple mini-projects.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="m-3 hovering text-decoration-none"
            as={Link}
            to={"/projects/website"}
          >
            <Card.Img
              variant="top"
              style={{
                objectFit: "cover",
                maxHeight: "10rem",
                width: "100%",
                height: "100%",
              }}
              src={website}
            />
            <Card.Body>
              <Card.Title>Oskar-Andersson.com</Card.Title>
              <Card.Text>
                Personal Website: A portfolio website hosted on Firebase, using
                a custom domain from Squarespace. The site showcases my skills
                and includes private projects that feature backend
                functionalities like database management and security. These are
                locked behind a login page.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={6} xs={10} className="p-0 m-0">
          <Card
            className="m-3 hovering text-decoration-none"
            as={Link}
            to={"/projects/bachelor"}
          >
            <Card.Img
              variant="top"
              style={{
                objectFit: "cover",
                maxHeight: "10rem",
                width: "100%",
                height: "100%",
              }}
              src={lnu}
            />
            <Card.Body>
              <Card.Title>Bachelor degree in systems science</Card.Title>
              <Card.Text>
                Bachelor's Degree in Systems Science: A three-year program at
                Linnaeus University, Sweden. Covered the fundamentals of
                Object-Oriented Programming (OOP), web development, SQL,
                testing, and Java, along with elements of business and
                management.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="m-3 hovering text-decoration-none"
            as={Link}
            to={"/projects/develoft"}
          >
            <Card.Img
              variant="top"
              style={{
                objectFit: "cover",
                maxHeight: "10rem",
                width: "100%",
                height: "100%",
              }}
              src={wip}
            />
            <Card.Body>
              <Card.Title>Develoft Games</Card.Title>
              <Card.Text>
                A hobby project undertaken with friends to develop mini-games
                using Unity 2D. Further details to come.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
