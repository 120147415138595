import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Menu() {
  return (
    <Navbar bg="white" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to={"/about"}>
          <img
            alt="Profile picture"
            src="https://picsum.photos/200/300"
            width="40"
            height="40"
            as={Link}
            to={"/"}
            className="d-inline-block me-3 rounded-circle"
          />
          <span className="fs-4 align-middle">Oskar Andersson</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              className="fs-4 me-5 custom-nav-link hovering"
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about"
              className="fs-4 me-5 custom-nav-link hovering"
            >
              About
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={"/projects"}
              className="fs-4 me-5 custom-nav-link hovering"
            >
              Projects
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={"/contact"}
              className="fs-4 me-5 custom-nav-link hovering"
            >
              Contact
            </Nav.Link>
            <Nav.Link as={Link} to={"/private"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="black"
                viewBox="0 0 16 16"
                className="hovering"
              >
                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5v-1a1.9 1.9 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm7 0a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
              </svg>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
