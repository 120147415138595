import { Card, Col, Container, Row } from "react-bootstrap";

export function AboutPage() {
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs={12} md={8} lg={6} className="text-center">
          <h1>About me</h1>
          <Card className="border-0 bg-transparent">
            <Card.Body>
              <Card.Text>
                I'm a software developer in my twenties from Sweden,
                specializing in both front-end and back-end development. With a
                recent Bachelor's degree in Systems Science, I'm currently a
                Junior Developer at Experis Academy. This page offers insights
                into my skills in programming and my general interests.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={6} sm={12} className="pb-5">
          <Card>
            <Card.Body>
              <Card.Title>Who am I?</Card.Title>
              <Card.Text>I am a guy</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} sm={12} className="pb-5">
          <Card>
            <Card.Body>
              <Card.Title>Skillset</Card.Title>
              <Card.Text>Not much to be honest</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
