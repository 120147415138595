import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

export function HomePage() {
  return (
    <Container className="h-100 p-5">
      <Row className="justify-content-center">
        <Col lg={10} md={10} sm={12} className="text-center p-0">
          <Card className="bg-transparent border-0">
            <Card.Body className="p-0">
              <Card.Title>
                <h1>Hello and Welcome!</h1>
              </Card.Title>

              <Card.Text className="home-text p-2">
                <span style={{ fontWeight: "500" }}>
                  I am a Junior Developer working to advance in full-stack
                  development. On the frontend, I specialize in{" "}
                  <span style={{ textDecoration: "underline" }}>
                    JavaScript
                  </span>{" "}
                  and <span style={{ textDecoration: "underline" }}>React</span>
                  , while my backend capabilities are focused on{" "}
                  <span style={{ textDecoration: "underline" }}>Java</span> and{" "}
                  <span style={{ textDecoration: "underline" }}>Spring</span>,
                  with a strong understanding of{" "}
                  <span style={{ textDecoration: "underline" }}>
                    RESTful APIs
                  </span>
                  .
                </span>{" "}
                My skillset is ever-expanding, as I continuously seek new
                challenges to further my knowledge and expertise. Feel free to
                explore my website, and should you have any questions, don't
                hesitate to contact me.
              </Card.Text>
            </Card.Body>
          </Card>
          <img
            className="mt-5 mb-1 img-fluid rounded-circle"
            src="https://picsum.photos/200/200"
          />
        </Col>
      </Row>
    </Container>
  );
}
