import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

export function ProtectedRoute({ children }) {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const value = parseInt(prompt("Enter password"));
    if (value === 1234) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, []);

  if (auth === null) {
    return null;
  }

  return auth ? children : <Navigate to="/" />;
}
