import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";
import facebookIcon from "../assets/facebook_icon_grey.png";
import instagramIcon from "../assets/instagram_icon_grey.png";
import linkedinIcon from "../assets/linkedin_icon_grey.png";
import mailIcon from "../assets/mail_icon_grey.png";
import "../../src/App.css";
import { Link } from "react-router-dom";

export function ContactPage() {
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isValid, setIsValid] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValue.email === "" || formValue.message === "" || formValue.name === "") {
      setIsValid(false);
      return;
    } else {
      alert("Form submitted");

      // Will send an email to me when the form is submitted but will fix it in the backend with java

      setFormValue({
        name: "",
        email: "",
        message: "",
      });
    }
  };

  return (
    <Container>
      <Row className="text-center mt-5">
        <h1>Contact</h1>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="text-center">
          <Card className="border-0 bg-transparent">
            <Card.Body>
              <Card.Text>
                If you have any questions or would like to get in touch, please
                send me a message with the form below or through my social
                media.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={6} xl={6} xxl={6} md={8} className="p-4 mt-5 shadow rounded-5">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicName" className="mb-3">
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                className="mb-3"
                maxLength={100}
                value={formValue.name}
                onChange={(e) => {
                  const trimmedName = e.target.value.replace(/\s+$/, " ");
                  setFormValue((prevState) => ({
                    ...prevState,
                    name: trimmedName,
                  }));
                }}
              />

            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label className="fw-bold">Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                className="mb-3"
                maxLength={100}
                value={formValue.email}
                onChange={(e) => {
                  setFormValue((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicMessage" className="mb-3">
              <Form.Label className="fw-bold">Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Type your message here..."
                className="mb-3"
                maxLength={1000}
                value={formValue.message}
                onChange={(e) => {
                  const trimmedMessage = e.target.value.replace(/\s+$/, " ");
                  setFormValue((prevState) => ({
                    ...prevState,
                    message: trimmedMessage,
                  }));
                }}
              />
            </Form.Group>
            {!isValid && (
              <p className="text-danger">All fields are required</p>
            )}
            <Form.Group className="d-flex justify-content-center">
              <Form.Control
                type="submit"
                value="Submit"
                className="btn btn-success w-25"
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          lg={6}
          xl={6}
          xxl={6}
          md={8}
          className="mt-5 mb-5 shadow rounded-5"
        >
          <Card className="w-100 bg-transparent border-0 p-1">
            <Card.Body className="p-0 d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-around w-100">
                <Col className="text-center">
                  <a
                    href="https://www.facebook.com/profile.php?id=100009201441172" // Will change this to my linkedin profile
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="m-1 hovering icon-size"
                      src={facebookIcon}
                      alt="Facebook"
                    />
                  </a>
                </Col>
                <Col className="text-center">
                  <a
                    href="https://www.instagram.com/oskarz991/?next=%2F" // Will change this to my linkedin profile
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="m-1 hovering icon-size"
                      src={instagramIcon}
                      alt="Instagram"
                    />
                  </a>
                </Col>
                <Col className="text-center">
                  <a
                    href="https://www.linkedin.com/in/oskar-andersson-133052251/" // Will change this to my linkedin profile
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="m-1 hovering icon-size"
                      src={linkedinIcon}
                      alt="Linkedin"
                    />
                  </a>
                </Col>
                <Col className="text-center">
                  <a
                    href="mailto:oskarz991@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="m-1 hovering icon-size"
                      src={mailIcon}
                      alt="Mail"
                    />
                  </a>
                </Col>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
