import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Menu } from "./components/Menu";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ContactPage } from "./pages/ContactPage";
import { HomePage } from "./pages/HomePage";
import { NotFound } from "./pages/NotFound";
import { PrivatePage } from "./pages/PrivatePage";
import { ProjectsPage } from "./pages/ProjectsPage";
import { AboutPage } from "./pages/AboutPage";
import { ProjectDetailPage } from "./pages/ProjectDetailPage";

function App() {
  return (
    <Router>
      <Container fluid className="full-screen">
        <Menu></Menu>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/:project" element={<ProjectDetailPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/private"
            element={<ProtectedRoute children={<PrivatePage />} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
